import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

const dayAhead2023 = require("../../data/DA_2023-2024.csv");
const dayAhead2024 = require("../../data/DA_2024-2025.csv");

const parseCSV = (url) => {
    return new Promise((resolve, reject) => {
        Papa.parse(url, {
            download: true,
            header: true,
            skipEmptyLines: true,
            complete: (results) => resolve(results.data),
            error: (error) => reject(error),
        });
    });
};

const fetchCSVData = async () => {
    try {
        const [data2023, data2024] = await Promise.all([
            parseCSV(dayAhead2023),
            parseCSV(dayAhead2024)
        ]);
        return [...data2023, ...data2024];
    } catch (error) {
        throw new Error('Error fetching CSV data: ' + error.message);
    }
};

const filterDataByDate = (data, startDate, endDate) => {
    return data.filter(item => {
        const itemDate = new Date(item.Datum);
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });
};

const calculateAverages = (data, setAveragePrice, setAverageLowPrice, setAverageHighPrice) => {
    if (data.length === 0) {
        setAveragePrice(0);
        setAverageLowPrice(0);
        setAverageHighPrice(0);
        return;
    }

    const prices = data.map(item => {
        const price = parseFloat(item['Prijs [EUR/MWh]']);
        return {
            dateTime: item.Datum,
            hourInterval: item.Tijdinterval,
            price: isNaN(price) ? 0 : price
        };
    });

    const total = prices.reduce((acc, { price }) => acc + price, 0);
    const avg = prices.length > 0 ? total / prices.length : 0;

    const groupedByDate = prices.reduce((acc, { dateTime, price }) => {
        if (!acc[dateTime]) acc[dateTime] = [];
        acc[dateTime].push(price);
        return acc;
    }, {});

    const avgLow = Object.values(groupedByDate).reduce((acc, dayPrices) => {
        const lowFour = dayPrices.sort((a, b) => a - b).slice(0, 4);
        return acc + (lowFour.reduce((sum, price) => sum + price, 0) / lowFour.length);
    }, 0) / Object.keys(groupedByDate).length;

    const avgHigh = Object.values(groupedByDate).reduce((acc, dayPrices) => {
        const highFour = dayPrices.sort((a, b) => b - a).slice(0, 4);
        return acc + (highFour.reduce((sum, price) => sum + price, 0) / highFour.length);
    }, 0) / Object.keys(groupedByDate).length;

    setAveragePrice(avg / 1000);
    setAverageLowPrice(avgLow / 1000);
    setAverageHighPrice(avgHigh / 1000);
};

const DayAhead = ({ setAveragePrice, setAverageLowPrice, setAverageHighPrice, isPeriodConfirmed, setIsPeriodConfirmed }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState('2024-08-01');
    const [endDate, setEndDate] = useState('2024-08-31');

    const handleFetchData = async () => {
        try {
            const data = await fetchCSVData();
            const filteredData = filterDataByDate(data, startDate, endDate);
            calculateAverages(filteredData, setAveragePrice, setAverageLowPrice, setAverageHighPrice);
            setIsPeriodConfirmed(true);
        } catch (err) {
            setError(err.message);
            setIsPeriodConfirmed(false);
        } finally {
            setLoading(false);
        }
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        if (new Date(newStartDate) > new Date(endDate)) {
            setError('Startdatum mag niet na de einddatum zijn.');
        } else {
            setStartDate(newStartDate);
            setError(null);
        }
        setIsPeriodConfirmed(false);
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        if (new Date(newEndDate) < new Date(startDate)) {
            setError('Einddatum mag niet voor de startdatum zijn.');
        } else if (new Date(newEndDate) > new Date(endDate)) {
            setError(`Einddatum mag niet later zijn dan ${endDate}.`);
        } else {
            setEndDate(newEndDate);
            setError(null);
        }
        setIsPeriodConfirmed(false);
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    return (
        <>
            <h2 className="offerte__header">Day Ahead Periode</h2>
            {error && <div className="offerte__warning">{error}</div>}
            <div className="offerte__form-group offerte__form-group--inline">
                <div className="offerte__form-item">
                    <label className="offerte__label" htmlFor="startDate">Van</label>
                    <input
                        className="offerte__input"
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="offerte__form-item">
                    <label className="offerte__label" htmlFor="endDate">tot</label>
                    <input
                        className="offerte__input"
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>
                <div className="offerte__form-item">
                    <button
                        className={`offerte__button ${isPeriodConfirmed ? 'offerte__button--confirmed' : 'offerte__button--not-confirmed'} ${loading ? 'offerte__button--loading' : ''}`}
                        onClick={isPeriodConfirmed || loading ? null : handleFetchData}
                        disabled={isPeriodConfirmed || loading}
                    >
                        {loading ? 'Loading...' : isPeriodConfirmed ? 'Periode bevestigd' : 'Bevestig periode'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default DayAhead;