import './dashboardOverview.css'
import Measurement from '../../../api/measurements'
import { useState, useEffect, useContext } from 'react'
import { DarkModeContext } from '../../../context/DarkModeContext'

import LineUp from '../../../Images/Line 30.png'
import Sunny from '../../../Images/Ellipse 30.png'
import ExportArrow from '../../../Images/exportarrow.png'
import ImportArrow from '../../../Images/importarrow.png'
import DashboardFlows from './Flows/dashboardFlows'
import BatteryStats from './BatteryStats/batteryStats'
import DashboardRelais from './Relay/dashboardRelais'
import GraphContainer from './Graph/graphContainer'
import DashboardSavings from './Savings/dashboardSavings'
import FlowValues from '../../../api/flowValues'

const DashboardOverview = ({ ToggleLoading, serial_Nr }) => {
    
    const [hideSavings, setHideSavings] = useState(false);

    const {darkMode} = useContext(DarkModeContext);
    const flowApi = FlowValues();
    const measurementApi = Measurement();
    const [screenWidth, setScreenWidth] = useState();
    const [serialNr, setSerialNr] = useState("");
    const [currentFlowData, setCurrentFlowData] = useState({
        SolarW: 0,
        GridW: 0,
        BatteryW: 0,
        HomeW: 0,
        BatteryP: 0,
    })
    const [maxBattCap, setMaxBattCap] = useState(0)
    const [battState, setBattState] = useState('Stand-By')
    const [battInstallation, setBattInstallation] = useState([])

    const updateMaxBattCap = (newMaxBattCap) => {
        setMaxBattCap(newMaxBattCap)
    }

    const updateBattState = (newBattState) => {
        setBattState(newBattState)
    }
    
    const updateBattInstallation = (battInstallation) => {
        let result = [];
        let count = 0;

        for (let i = 0; i < battInstallation.length; i++) {
            count++;

            // If next element is different or it's the last element, create a record
            if (i === battInstallation.length - 1 || battInstallation[i] !== battInstallation[i + 1]) {
            result.push({ unit: count, value: battInstallation[i] });
            count = 0; // Reset count for the next group
            }
        }

        setBattInstallation(result)
    }

    useEffect(() => {
        setSerialNr(serial_Nr)
        setScreenWidth(window.innerWidth)
    }, [serial_Nr])

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const [batteryCapacity, setBatteryCapacity] = useState(20);
    const [batteryState, setBatteryState] = useState("Charging");

    //flow data for new dashboard
    const getFlowData = async() => {
        try {
            const data = await flowApi.getFlowValues(serialNr);
            
            if (data) {
                setCurrentFlowData({
                    SolarW: data.pv_power.value,
                    GridW: data.grid_power.value,
                    BatteryW: data.battery_power.value,
                    HomeW: data.consumer_power.value,
                    BatteryP: Math.ceil(data.battery_soc.value * 100)
                })
            } else {
                setCurrentFlowData({
                    SolarW: 0,
                    GridW: 0,
                    BatteryW: 0,
                    HomeW: 0,
                    BatteryP: 0
                })
            }
        } catch (error) {
            console.log(error)

            setCurrentFlowData({
                SolarW: 1,
                GridW: 1,
                BatteryW: 1,
                HomeW: 1,
                BatteryP: 1
            })
        }
        
    }

    useEffect(() => {
        const getFlowDataEveryMinute = () => {
            if (serialNr) {
                getFlowData();
            }
        };

        const intervalId = setInterval(getFlowDataEveryMinute, 60000);
        getFlowDataEveryMinute();
        return () => clearInterval(intervalId);

    }, [serialNr])

    const toggleHidden = (hide) => {
        setHideSavings(hide);
    }

    
    return (
        <div style={darkMode ? {backgroundColor:"#121212"} : {backgroundColor:"#D9D9D9", color: 'black'}} className='Dashboard-Body-Overview-Container'>
            
            <div className='Dashboard-Body-Overview-Rows'>
                <div className='Dashboard-Body-Overview-Row'>
                    <div className='Dashboard-Body-Overview-Row-Element-Container Flows' style={{minWidth:"400px", maxWidth:"400px", minHeight: "400px", backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)' }}>
                        <DashboardFlows updateBattState={(newBattState) => updateBattState(newBattState)} serialNr={serial_Nr} data={currentFlowData}/>
                    </div>
                    {/* <div style={{display: screenWidth > 1600 ? 'flex' : 'none' , backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}} className='Dashboard-Body-Overview-Row-Element-Container Extra'>
                        <DashboardRelais Relais1={1000} Relais2={0}/>
                    </div> */}
                    <div className='Dashboard-Body-Overview-Row-Element-Container Profit' style={{visibility: hideSavings ? 'hidden' : 'visible', backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                        <DashboardSavings updateBattInstallation={(battInstallation) => updateBattInstallation(battInstallation)} updateMaxBattCap={(maxBattCap) => updateMaxBattCap(maxBattCap)} toggleHidden={toggleHidden} serialNr={serialNr}/>
                    </div>
                    <div className='Dashboard-Body-Overview-Row-Element-Container Battery' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                        <BatteryStats battInstallation={battInstallation} battState={battState} maxBattCap={maxBattCap} serialNr={serialNr} batteryPercentage={currentFlowData.BatteryP} batteryState={batteryState}/>
                    </div>
                </div>
                <div className='Dashboard-Body-Overview-Row'>
                    <div className='Dashboard-Body-Overview-Row-Element-Container Graphs' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                        <GraphContainer serialNr={serialNr}/>
                    </div>
                </div>
                {/* <div className='Dashboard-Body-Overview-Row'>
                    <div className='Dashboard-Body-Overview-Row-Element-Container Graphs' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                        <GraphContainer serialNr={serialNr}/>
                    </div>
                </div> */}
                {/* <div className='Dashboard-Body-Overview-Row'>
                    <div style={{display: screenWidth <= 1600 ? 'flex' : 'none' , backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}} className='Dashboard-Body-Overview-Row-Element-Container Extra'>
                        <DashboardRelais Relais1={1000} Relais2={0}/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default DashboardOverview