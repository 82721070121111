import { Label, ComposedChart, CartesianGrid , Legend, CartesianAxis, ResponsiveContainer, XAxis, YAxis, Bar } from 'recharts'
import './graphMaker.css'
import { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { addHours, format, startOfDay } from 'date-fns';

const GraphMaker = ({data, chartObjects, handleSelectedItemRemove, date}) => {

    const generateHourlyTicks = () => {
        const start = startOfDay(date);
        return Array.from({ length: 24 }, (_, i) => addHours(start, i).getTime());
    };
    
    const [hourlyTicks, setHourlyTicks] = useState(generateHourlyTicks())

    const handleLegendClick = (event) => {
        handleSelectedItemRemove(event.dataKey);
        //look at the datakey
    }

    useEffect(() => {
        setHourlyTicks(generateHourlyTicks())
    }, [date])

    
    const [isPercentageNeeded, setIsPercentageNeeded] = useState(false);
    return (
        <ResponsiveContainer style={{fontSize: "1rem", marginTop: 20}} width={"100%"} height="85%">
            <ComposedChart data={data} key={date.toISOString()}>
                <XAxis interval={1} dataKey="timestamp" type='number' domain={['dataMin - 1800000', 'dataMax + 1800000']} ticks={hourlyTicks} tickFormatter={(tick) => format(new Date(tick), "HH:mm")}/>
                <YAxis tickFormatter={(tick) => tick < 0 ? tick < 5 ? Math.floor(tick / 1000) : (tick / 1000).toFixed(1) : tick < 5 ? (tick / 1000).toFixed(1) : Math.ceil(tick / 1000)} unit={'kW'} width={100}/>
                <YAxis yAxisId={'Percentage'} unit={'%'} orientation='right' domain={[0, 100]} hide={isPercentageNeeded}/>
                <CartesianGrid style={{opacity: 0.5}} vertical={false}/>
                
                {chartObjects.map((object, index) => {
                    if (object.object.props.yAxisId == 'Percentage') {
                        () => setIsPercentageNeeded(true);
                    }
                    return (
                        object.object
                    )
                }  
                )}
                <Legend wrapperStyle={{ color: '#555', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleLegendClick} />
            </ComposedChart>
        </ResponsiveContainer>
    );

}

export default GraphMaker